<i18n>
{
  "de": {
    "identifier": "Identifikation",
    "description": "Bezeichnung",
		"year": "Jahr",
		"measure": "Massnahme",
		"costs": "Investitionskosten",
		"s12e": "Scope 1-2",
		"s12eEmissions": "Scope 1-2 Emissionen (t CO₂e)",
		"reductionCosts": "Vermeidungskosten",
    "actions": {
			"windows": "Fensterersatz",
			"roof": "Sanierung Dach",
			"basement": "Sanierung Kellerdecke",
			"walls": "Sanierung Fassade",
			"heating": "Heizungsersatz",
			"pv": "Installation Photovoltaik",
      "electricity": "Änderung Netzanschluss",
      "zones": "Änderung von Flächen und Nutzung"
		},
		"allMeasures": "Alle Massnahmen",
		"measuresIn": "Massnahme | Massnahmen",
		"reduction": "Reduktion",
    "changeInPercent": "Änderung (Prozent)",
		"inTotal": "Gesamt",
		"total": "Total",
		"reset": "Zurücksetzen",
    "exportCSV": "CSV exportieren",
    "exportXLSX": "Excel exportieren",
    "noData": "Keine Massnahmen",
    "reductionAmount": "Reduktion: {amount} kg CO₂e",
    "noChange": "Keine Veränderung",
    "increaseAmount": "Zunahme: {amount} kg CO₂e",
    "s12eInfobox": "Scope 1-2 Emissionen",
    "s12eInfoboxText": "Direkte Emissionen in t CO₂e nach der Massnahme. Die Reduktion (oder Zunahme) ist die Differenz zwischen den Scope 1-2 Emissionen vor und nach der Massnahme."
  }
}
</i18n>

<template>
  <div class="c-costs-overview">
    <AsyncContentContainer :content-state="contentState" class="costs-overview-chart-container">
      <template #default>
        <FaultsContainer :faults="data?.faulty" :portfolio="portfolio" />
        <CostChart
          :data="data"
          :selected-year="selectedYear"
          class="costs-overview-chart"
          @selection-change="onYearSelectionChange"
        />
      </template>
    </AsyncContentContainer>

    <div class="costs-overview-table-container">
      <!-- Header -->
      <div class="overview-header">
        <!-- Title -->
        <h2>
          {{
            selectedYear
              ? `${selectedYear}: ${tableData.length} ${$tc('measuresIn', tableData.length)}`
              : $t('allMeasures')
          }}
        </h2>

        <div v-if="tableData.length > 0" class="table-controls">
          <!-- Sort -->
          <SortDropdown
            :sort-options="sortOptions"
            :selected-option="sortOrder"
            @update:selectedOption="onSortOptionChange"
          />
          <SortReverse :value="sortReverse" @update:value="onSortReverseChange" />
          <!-- Export -->
          <VPopover v-for="format in ['csv', 'xlsx']" :key="format" trigger="hover">
            <button
              type="button"
              class="button button--square"
              :disabled="exportLoading[format]"
              @click="onExport(format)"
            >
              <img v-if="!exportLoading[format]" class="icon" src="/icons/download.svg" />
              <ClipLoader v-else class="export-spinner" size="18px" color="#000" />
            </button>
            <template slot="popover">
              <main>{{ $t(`export${format.toUpperCase()}`) }}</main>
            </template>
          </VPopover>
          <!-- Reset button -->
          <Button v-if="selectedYear" icon="refresh-ccw" :text="$t('reset')" @click="onReset" />
        </div>
      </div>

      <DetailList v-if="tableData.length > 0" has-header>
        <!-- Headers -->
        <template #header>
          <HeaderEntry :title="$t('identifier')" class="id" />
          <HeaderEntry :title="$t('description')" class="name" />
          <HeaderEntry :title="$t('year')" class="year" />
          <HeaderEntry :title="$t('measure')" class="measures" />
          <HeaderEntry
            :title="$t('s12e')"
            :infobox-title="$t('s12eInfobox')"
            :infobox-text="$t('s12eInfoboxText')"
            :unit="`t CO₂e (${$t('reduction')})`"
            class="emissions"
            align-right
          />
          <HeaderEntry :title="$t('costs')" unit="CHF" class="costs" align-right />
          <HeaderEntry :title="$t('reductionCosts')" unit="CHF/kg CO₂e" class="reduction-costs" align-right />
        </template>

        <!-- Rows, data -->
        <template>
          <li v-for="item in tableData" :key="item.id">
            <!-- ID (Identifier) -->
            <div class="id">
              <router-link
                :to="{ name: 'buildingDetails', params: { portfolio_id: portfolio.id, building_id: item.building_id } }"
              >
                <strong>
                  {{ item.building_identifier }}
                </strong>
              </router-link>
            </div>

            <!-- Name (Description) -->
            <div class="name">
              <router-link
                :to="{ name: 'buildingDetails', params: { portfolio_id: portfolio.id, building_id: item.building_id } }"
              >
                <strong>
                  {{ item.building_description }}
                </strong>
              </router-link>
            </div>

            <!-- Year -->
            <div class="year">
              <strong>
                {{ item.year }}
              </strong>
            </div>

            <!-- Measures -->
            <div class="measures align-right">
              <div class="measures-list align-left">
                <strong>
                  {{ $t('inTotal') }}
                </strong>
              </div>
              <div
                v-for="(item, index) in getActionsWithCosts(item.costs)"
                :key="`${index}-${item.name}`"
                class="measures-list"
              >
                <div class="measure-name no-wrap">
                  <div class="measure-icon" :style="{ color: item.color }">■</div>
                  {{ item.name }}
                </div>
              </div>
            </div>

            <!-- Scope 1-2 Emissions -->
            <div class="emissions align-right">
              <strong>
                {{ `${formatNumberWithThreshold(item.next_s12e, 2, 1, 1)} ` }}
              </strong>

              <!-- Percentage -->
              <VPopover placement="bottom" trigger="hover">
                <span v-if="item.difference_s12e.value === 0" class="gray">{{ `(-)` }}</span>
                <span v-else class="gray">
                  {{
                    `(${getDifferenceIcon(item.difference_s12e.value)}
                  ${formatNumber(item.difference_s12e.percentage_value, 1)}%)`
                  }}
                </span>

                <template slot="popover">
                  <main v-if="item.difference_s12e.value > 0">
                    {{ $t('reductionAmount', { amount: formatNumber(item.difference_s12e.value, 1) }) }}
                  </main>
                  <main v-else-if="item.difference_s12e.value === 0">
                    {{ $t('noChange') }}
                  </main>
                  <main v-else>
                    {{
                      $t('increaseAmount', {
                        amount: formatNumber(Math.abs(item.difference_s12e.value), 1),
                      })
                    }}
                  </main>
                </template>
              </VPopover>
            </div>

            <!-- Costs -->
            <div class="costs align-right">
              <div class="align-right">
                <strong>
                  {{ formatNumber(item.costs.total, -3) }}
                </strong>
              </div>
              <div v-for="(item, index) in getActionsWithCosts(item.costs)" :key="`${index}-${item.name}`">
                {{ formatNumber(item.costs, -3) }}
              </div>
            </div>

            <!-- Reduction Costs -->
            <div class="reduction-costs align-right">
              <strong v-if="item.difference_s12e.costs >= 0">
                {{ formatNumberWithThreshold(item.difference_s12e.costs, 0, 10, -1) }}
              </strong>
              <strong v-else>-</strong>
            </div>
          </li>

          <!-- Summary row -->
          <li v-if="tableData.length > 0" class="summary-row">
            <div class="id">
              <strong>
                {{ $t('total') }}
              </strong>
            </div>
            <div class="name" />
            <div class="year" />
            <div class="measures" />
            <div class="emissions align-right" />
            <div class="costs align-right">
              <strong>
                {{ formatNumber(summaryData.investmentCosts, -3) }}
              </strong>
            </div>
            <div class="reduction-costs align-right">
              <strong v-if="summaryData.reductionCosts > 0">
                {{ formatNumberWithThreshold(summaryData.reductionCosts, 0, 10, -1) }}
              </strong>
              <strong v-else>
                {{ '-' }}
              </strong>
            </div>
          </li>
        </template>
      </DetailList>

      <!-- No data row -->
      <div v-if="tableData.length === 0" class="no-data">
        {{ $t('noData') }}
      </div>

      <!-- Pagination -->
      <div v-if="measuresData.length > 0" class="pagination">
        <Pagination :data="measuresData" :page-size="20" @update:page="onPageUpdate" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'
import { VPopover } from 'v-tooltip'

import store from '@/store'
import compassApi from '@/services/compass-api.js'
import colorPalettes from '@/services/color-palettes.js'

import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'

import Button from '@/components/cui/inputs/Button.vue'
import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import CostChart from '@/components/report/charts/CostChart.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'
import SortDropdown from '@/components/shared/SortDropdown.vue'
import SortReverse from '@/components/shared/SortReverse.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Pagination from '@/components/shared/lists/Pagination.vue'

export default {
  mixins: [
    //
    AsyncContentMixin,
  ],

  components: {
    //
    Button,
    AsyncContentContainer,
    FaultsContainer,
    CostChart,
    DetailList,
    HeaderEntry,
    SortDropdown,
    SortReverse,
    VPopover,
    ClipLoader,
    Pagination,
  },

  props: {
    portfolio: {
      type: Object,
    },
    scenario: {
      type: Object,
    },
  },

  data() {
    return {
      data: null,
      measures: null,
      selectedYear: null,
      sortReverse: false,
      sortOrder: 'building_identifier',
      sortOptions: [
        {
          label: this.$t('identifier'),
          value: 'building_identifier',
          sorterFn: (a, b) => a.building_identifier.localeCompare(b.building_identifier),
        },
        {
          label: this.$t('description'),
          value: 'building_description',
          sorterFn: (a, b) => a.building_description.localeCompare(b.building_description),
        },
        { label: this.$t('year'), value: 'year', sorterFn: (a, b) => a.year - b.year },
        { label: this.$t('s12e'), value: 'next_s12e', sorterFn: (a, b) => a.next_s12e - b.next_s12e },
        { label: this.$t('costs'), value: 'costs.total', sorterFn: (a, b) => a.costs.total - b.costs.total },
        {
          label: this.$t('reductionCosts'),
          value: 'difference_s12e.costs',
          sorterFn: (a, b) => a.difference_s12e.costs - b.difference_s12e.costs,
        },
      ],
      exportLoading: {
        csv: false,
        xlsx: false,
      },
      tableData: [],
    }
  },

  computed: {
    ...mapState('reporting', ['exportCsvLoading', 'exportXlsxLoading']),
    ...mapGetters({
      query: 'query/get',
    }),

    measuresData() {
      const result =
        this.measures
          // Filter by selected year if one is selected
          ?.filter((measure) => !this.selectedYear || measure.year === this.selectedYear)
          // Sort by selected option
          ?.sort((a, b) => {
            const sorter = this.sortOptions.find((option) => option.value === this.sortOrder)?.sorterFn
            return this.sortReverse ? -sorter(a, b) : sorter(a, b)
          }) || []

      return result
    },

    summaryData() {
      const investmentCosts = _.sumBy(this.tableData, 'costs.total')
      const reductionCosts = _.sumBy(this.tableData, 'difference_s12e.value')

      return {
        // TODO: Add total co2 at end state (sum of end states), and co2 reduction compared to start state (sum of start states)
        investmentCosts,
        reductionCosts,
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    //
    async loadContentHandler(signal) {
      const query = JSON.stringify(this.query)
      this.data = null
      this.measures = null
      this.tableData = []
      await Promise.all([
        compassApi.call(
          `/projections/${this.portfolio.id}/costs/by_type/${this.scenario.id}?query=${query}`,
          'GET',
          null,
          signal
        ),
        compassApi.call(
          `/projections/${this.portfolio.id}/measures/${this.scenario.id}?query=${query}`,
          'GET',
          null,
          signal
        ),
      ]).then(([data, measures]) => {
        this.data = data
        this.measures = measures
      })
    },

    //
    getActionsWithCosts(data) {
      const result = []
      _.keys(_.omit(data, 'total')).forEach((key) => {
        result.push({
          name: this.$t(`actions.${key}`),
          color: colorPalettes.measureColors[key.toUpperCase()],
          costs: data[key],
        })
      })
      return result
    },

    //
    getDifferenceIcon(value) {
      return value < 0 ? '↑' : '↓'
    },

    //
    onYearSelectionChange(year) {
      this.selectedYear = year
    },

    //
    onReset() {
      this.selectedYear = null
    },

    //
    onSortOptionChange(sortAttribute) {
      this.sortOrder = sortAttribute
    },

    //
    onSortableHeaderClick(sortAttribute) {
      if (this.sortOrder === sortAttribute) {
        this.sortReverse = !this.sortReverse
      } else {
        this.sortOrder = sortAttribute
        this.sortReverse = false
      }
    },

    //
    onSortReverseChange(value) {
      this.sortReverse = value
    },

    //
    onPageUpdate(pageData) {
      this.tableData = pageData
    },

    //
    async onExport(format) {
      const token = store.getters['account/token']
      const headers = {
        'Content-Type': 'application/json',
      }
      if (token) {
        headers['Authentication-Token'] = token
      }
      try {
        const query = JSON.stringify(this.query)
        this.exportLoading[format] = true
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/export/portfolio/${this.portfolio.id}/measures/${this.scenario.id}/${format}?query=${query}`,
          {
            method: 'POST',
            headers,
            body: JSON.stringify({
              filter: {
                year: this.selectedYear,
              },
              sort: {
                order: this.sortOrder,
                reverse: this.sortReverse,
              },
              translations: {
                header: [
                  this.$t('identifier'),
                  this.$t('description'),
                  this.$t('year'),
                  this.$t('measure'),
                  `${this.$t('s12eEmissions')}`,
                  `${this.$t('changeInPercent')}`,
                  `${this.$t('costs')} (CHF)`,
                  `${this.$t('reductionCosts')} (CHF/kg CO₂e)`,
                ],
                actions: {
                  total: this.$t('inTotal'),
                  windows: this.$t('actions.windows'),
                  roof: this.$t('actions.roof'),
                  basement: this.$t('actions.basement'),
                  walls: this.$t('actions.walls'),
                  heating: this.$t('actions.heating'),
                  pv: this.$t('actions.pv'),
                  electricity: this.$t('actions.electricity'),
                  zones: this.$t('actions.zones'),
                },
              },
            }),
          }
        )
        if (response.ok) {
          const blob = await response.blob()
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')

          link.href = url
          link.download = `measures-export.${format}`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        } else {
          throw new Error(`Error: ${response.statusText}`)
        }
      } finally {
        this.exportLoading[format] = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-costs-overview {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);

  @media screen and (max-width: 1800px) {
    flex-direction: column;
  }

  & .costs-overview-chart-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    border: var(--box-border);
    border-radius: var(--box-radius);
    padding: var(--spacing-s);

    & > * {
      flex: 1;
    }

    & .costs-overview-chart {
      display: flex;
      flex-direction: column;
      min-height: 568px;
    }
  }

  & .costs-overview-table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow: hidden;

    & .overview-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & h2 {
        margin: var(--spacing-m) 0;
      }
    }

    & .measures-list {
      & .measure-icon {
        position: absolute;
        left: 0;
      }

      & .measure-name {
        position: relative;
        padding-left: var(--spacing-m);
        text-align: left;
      }
    }
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .align-left {
    text-align: left;
    justify-content: flex-start;
  }

  & .no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .gray {
    color: #888;
  }

  & .no-data {
    text-align: center;
    padding: var(--spacing-m);
    border: var(--box-border);
    border-radius: var(--box-radius);
  }
}
</style>

<style lang="scss">
.c-costs-overview {
  & .costs-overview-chart-container {
    & .chart-container {
      flex: 1;
    }
  }

  & .costs-overview-table-container {
    & .detail-list {
      overflow: hidden;
      width: 100%;
      height: 100%;
      font-size: 13px;

      & ul {
        overflow: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        max-height: 500px;

        & li {
          display: flex;
          flex-direction: row;
          min-width: fit-content;
          width: 100%;
          align-items: stretch;

          &.summary-row {
            position: sticky;
            bottom: 0;
            background-color: var(--background-color);
          }

          & > * {
            flex: 1;
            width: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          & .id {
            flex: none;
            width: 80px;
          }

          & .name {
            min-width: 160px;
          }

          & .year {
            flex: none;
            width: 40px;
          }

          & .measures {
            min-width: 180px;
          }

          & .emissions {
            display: flex;
            flex-direction: row;
            gap: var(--spacing-xxs);
          }

          & .costs,
          & .emissions,
          & .reduction-costs {
            min-width: 140px;
          }
        }
      }
    }

    & .pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: var(--spacing-s);
    }
  }

  & .table-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 0;
    animation: fade-in 0.2s ease-in-out forwards;
    z-index: 5;

    @keyframes fade-in {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    & .v-popover .button {
      margin-left: var(--spacing-s);
    }

    & .export-spinner {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
